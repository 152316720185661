import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Material } from '../models/material';
import { MaterialPhase } from '../models/material-phase';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from './language.service';
import { __makeTemplateObject } from 'tslib';
import { PhaseType } from '../enums/phase-type';

@Injectable({
  providedIn: 'root'
})
export class MaterialService extends ApiService {
  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public sanitizer: DomSanitizer,
    public languageService: LanguageService
  ) {
    super(http, authenticationService);
  }

  getMaterials(patient_uid, patient_pathway_uid): Observable<MaterialPhase[]> {
    const url = `${environment.platformUrl}/patients/${patient_uid}/patient-pathways/${patient_pathway_uid}/educational-materials`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        let materialPhases: MaterialPhase[] = result.educational_materials?.map(phase => new MaterialPhase(phase));

        observer.next([...materialPhases]);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    })
  }

  getMaterialTranslationsFromCms(ids: string[]): Observable<Material[]> {
    const url = environment.cmsUrl + '/v3/content_types/educational_material/entries';
    const locale = this.languageService.getCurrentLanguage().locale;

    return new Observable(observer => {
      this.cmsGet(url, locale, null, ids).subscribe(result => {
        observer.next(result.entries);
        observer.complete();
      }, error => observer.error(error));
    });
  }

  getTranslatedMaterials(patient_uid: string, patient_pathway_uid: string): Observable<MaterialPhase[]> {
    return new Observable(observer => {
      this.getMaterials(patient_uid, patient_pathway_uid).subscribe((materialPhases: MaterialPhase[]) => {
        let ids: string[] = [];

        materialPhases.forEach((phase: MaterialPhase) => {
          ids.push(...this.getMaterialIds(phase));
        });

        // Remove duplicates
        ids = [...new Set(ids)];

        this.getMaterialTranslationsFromCms(ids).subscribe((materials: Material[]) => {
          materialPhases = this.mapTranslationsToMaterialPhases(materialPhases, materials);

          observer.next(materialPhases);
          observer.complete();
        }, error => observer.error(error));
      }, error => observer.error(error));
    });
  }

  getTranslatedMaterialsMappedToMainPhase(
    patient_uid: string,
    patient_pathway_uid: string
  ): Observable<MaterialPhase[]> {
    return new Observable(observer => {
      this.getTranslatedMaterials(patient_uid, patient_pathway_uid).subscribe((materialPhases: MaterialPhase[]) => {
        materialPhases.forEach((materialPhase: MaterialPhase) => {
          // Map all level 1 subphase materials to top main phase.
          // Lower level phases are already mapped to level 1 subphases in MaterialPhase-model.
          if (materialPhase.sub_phases?.length) {
            materialPhase.sub_phases.forEach((subPhase: MaterialPhase) => {
              materialPhase.educational_materials.push(...subPhase.educational_materials);
            });
          }
        });

        observer.next(materialPhases);
        observer.complete();
      }, error => observer.error(error));
    });
  }

  getMaterialIds(phase: MaterialPhase): string[] {
    let ids = [];

    if (phase?.educational_materials?.length) {
      for (let i = 0; i < phase.educational_materials.length; i++) {
        if (!ids.includes(phase.educational_materials[i].reference.key)) {
          ids.push(phase.educational_materials[i].reference.key)
        }
      }
    }

    if (phase?.sub_phases?.length) {
      phase.sub_phases.forEach((subPhase: MaterialPhase) => ids.push(...this.getMaterialIds(subPhase)));
    }

    return ids
  }

  mapTranslationsToMaterialPhases(materialPhases: MaterialPhase[], materials: Material[]): MaterialPhase[] {
    materialPhases.map(materialPhase => {
      return this.mapTranslationToSinlgeMaterialPhase(materialPhase, materials);
    });

    return materialPhases;
  }

  mapTranslationToSinlgeMaterialPhase(materialPhase: MaterialPhase, materials: Material[]): MaterialPhase {
    materialPhase.educational_materials.map((material: Material) => {
      const matchCms: Material = materials.find((materialCms: Material) => materialCms.uid === material.cmsUid);
      return material.fillFromCms(matchCms);
    });

    if (materialPhase.sub_phases?.length) {
      materialPhase.sub_phases.forEach(subPhase => {
        this.mapTranslationToSinlgeMaterialPhase(subPhase, materials);
      });
    }

    return materialPhase;
  }

  setMaterialProgress(patient_uid, pathway_uid, material_uid, progress): Observable<any> {
    const url = `${environment.platformUrl}/patients/${patient_uid}/patient-pathways/${pathway_uid}/educational-materials/${material_uid}`;
    const params = {
      progress
    }
    return new Observable(observer => {
      this.authenticatedPut(url, params).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
      });
    })
  }

  getSanitizedUrl(url) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  getBrightcoveEmbedUrl(videoId: string) {
    const url = `https://players.brightcove.net/${environment.brightcove_acc_id}/default_default/index.html?videoId=${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

