import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Patient } from '../models/patient';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { Pathway } from '../models/pathway';
import { PhaseInstance } from '../models/phase-instance';
import { GeneralService } from './general.service';
import { DataService } from './data.service';
import { Appointment } from '../models/appointment';
import { TranslationInterface } from '../interfaces/translation.interface';
import { CareModule } from '../models/care-module';
import { PathwayMessage } from '../models/pathway-message';
import { MaterialService } from './material.service';
import { MaterialPhase } from '../models/material-phase';
import { PhaseType } from '../enums/phase-type';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends ApiService {
  private readonly platformUrl: string;

  public static StorageKeyCurrentPatient = 'C4T_current_patient';
  public static StorageKeyCurrentCareModules = 'C4T_current_care_modules';

  constructor(
    public http: HttpClient,
    public authenticationService: AuthenticationService,
    public languageService: LanguageService,
    public translateService: TranslateService,
    protected generalService: GeneralService,
    private dataService: DataService,
    public materialService: MaterialService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  get(id: string): Observable<Patient> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${id}`;
      this.authenticatedGet(url).subscribe(result => {
        const patient = new Patient(result);
        observer.next(patient);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  update(patient: Patient): Observable<Patient> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/patients/${patient.uid}`;
      const params = this.postablePatient(patient);
      let profilePictureData: string;
      if (patient.profile_picture && patient.profile_picture.data) {
        profilePictureData = patient.profile_picture.data;
        patient.profile_picture.data = patient.profile_picture.data.split(',')[1];
      }

      this.authenticatedPut(url, params).subscribe(result => {
        patient = new Patient(result);
        this.storeCurrentPatientByModel(patient);

        if (profilePictureData) {
          this.dataService.set(`PROFILE_PICTURE_${patient.profile_picture.uuid}`, profilePictureData);
        }

        observer.next(patient);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  postablePatient(patient: Patient): Object {
    const data = {
      preferred_name: patient.preferred_name,
      phone_number: patient.phone_number,
      country: patient.country,
      language: patient.language,
      profile_picture: patient.profile_picture
      // email: patient.email,
      // contact_channel: patient.contact_channel
    };



    return data;
  }

  export(uid: string): Observable<Patient> {
    return new Observable(observer => {
      const url = this.platformUrl + '/patients/' + uid + '/export';
      this.authenticatedGet(url).subscribe(result => {
        const patient = new Patient(result);
        observer.next(patient);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPathways(): Observable<Pathway[]> {
    return new Observable(observer => {
      const patient_uid = this.getCurrentStoredPatientUid();
      const url = `${this.platformUrl}/patients/${patient_uid}/patient-pathways`;
      this.authenticatedGet(url, null, true).subscribe(result => {
        let pathways: Pathway[] = [];

        if (result.items) {
          pathways = result.items.map(item => this.mapPathway(item));
        }

        observer.next(pathways);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPathway(patientPathwayId: string): Observable<Pathway> {
    return new Observable(observer => {
      const patientUid = this.getCurrentStoredPatientUid();
      const url = `${this.platformUrl}/patients/${patientUid}/patient-pathways/${patientPathwayId}`;

      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapPathway(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPathwayDashboard(patient_pathway_id: string): Observable<Pathway> {
    const patient_uid = this.getCurrentStoredPatientUid();
    const url = `${this.platformUrl}/dashboards/patients/${patient_uid}/patient-pathways/${patient_pathway_id}`;

    return new Observable<Pathway>(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const pathway = new Pathway(result);
        observer.next(pathway);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPathwayPhases(patient_pathway_id: string): Observable<{ phases: PhaseInstance[], appointments: Appointment[] }> {
    return new Observable<{ phases: PhaseInstance[], appointments: Appointment[] }>(observer => {
      this.getPathwayDashboard(patient_pathway_id).subscribe(pathway => {
        const phases: PhaseInstance[] = [];
        const appointments: Appointment[] = pathway.appointments;

        pathway.phases.forEach(phase => {
          phase.type = PhaseType.PHASE;

          if (phase.sub_phase_instances) {
            this.generalService.sortByKey(phase.sub_phase_instances, 'order');

            phase.sub_phase_instances.forEach(subPhase => {
              subPhase.type = PhaseType.SUBPHASE;
            });
          }

          phases.push(phase);
        });

        this.generalService.sortByKey(phases, 'order');

        observer.next({ phases, appointments });
        observer.complete();
      }, () => {
        observer.error();
        observer.complete();
      });
    });
  }

  getPhaseContent(translation: TranslationInterface, keys: string[]): Observable<any> {
    const locale = this.languageService.getCurrentLanguage().locale;

    return new Observable<any>(observer => {
      if (keys.length === 0) {
        observer.next();
        observer.complete();
      }

      this.cmsRegionGet(translation, locale, null, keys).subscribe((result: any[]) => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPhasesWithMaterials(patient_pathway_id: string)
    : Observable<{
      phases: PhaseInstance[],
      appointments: Appointment[]
    }> {
    return new Observable<{ phases: PhaseInstance[], appointments: Appointment[] }>(observer => {
      forkJoin([
        this.getPathwayPhases(patient_pathway_id),
        this.materialService.getMaterials(this.getCurrentStoredPatientUid(), patient_pathway_id)
      ]).subscribe(result => {
        const pathway = result[0];
        const materialPhases: MaterialPhase[] = result[1];

        pathway.phases.forEach((phase: PhaseInstance) => {
          phase.type = PhaseType.PHASE;

          // Map materials to main phases
          this.mapMaterialsToPhase(phase, materialPhases);

          // Map all sub phase materials to level 1 sub phase.
          if (phase.sub_phase_instances?.length) {
            phase.sub_phase_instances.forEach((subPhase: PhaseInstance) => {
              subPhase.type = PhaseType.SUBPHASE;

              if(subPhase.phase_visible) {
                this.mapMaterialsToPhase(subPhase, materialPhases, subPhase);
              } else {
                this.mapMaterialsToPhase(subPhase, materialPhases, phase);
              }
            });
          }
        });

        observer.next(pathway);
        observer.complete();
      }, () => {
        observer.error();
        observer.complete();
      });
    });
  }

  mapMaterialsToPhase(phase: PhaseInstance, materialPhases: MaterialPhase[], parentPhase?: PhaseInstance) {
    let match: MaterialPhase;

    // Main materialPhases
    for (let i = 0; i < materialPhases.length; i++) {
      const materialPhaseIterator = materialPhases[i];

      if (phase.id === materialPhaseIterator.phase_id) {
        match = materialPhaseIterator;
        break;
      }

      // Sub materialPhases
      if (materialPhaseIterator.sub_phases?.length) {
        const subMaterialPhases = materialPhaseIterator.sub_phases;

        for (let j = 0; j < subMaterialPhases.length; j++) {
          if (phase.id === subMaterialPhases[j].phase_id) {
            match = subMaterialPhases[j];
            break;
          }
        }
      }
    }

    if (!phase?.learning_materials) {
      phase.learning_materials = [];
    }

    if (match) {
      parentPhase ?
        parentPhase.learning_materials.push(...match.educational_materials) :
        phase.learning_materials.push(...match.educational_materials)
    }

    if (parentPhase && phase?.sub_phase_instances?.length) {
      phase.sub_phase_instances.map((subPhase: PhaseInstance) => {
        this.mapMaterialsToPhase(subPhase, materialPhases, parentPhase);
      });
    }
  }

  getTranslatedPhases(patient_pathway_id: string): Observable<{ phases: PhaseInstance[], appointments: Appointment[] }> {
    return new Observable<{ phases: PhaseInstance[], appointments: Appointment[] }>(observer => {

      this.getPhasesWithMaterials(patient_pathway_id).subscribe((pathway: { phases: PhaseInstance[], appointments: Appointment[] }) => {
        const phaseTranslationKeys: string[] = [];
        const phases: PhaseInstance[] = pathway.phases;
        const appointments: Appointment[] = pathway.appointments;

        phases.forEach((phase: PhaseInstance) => {
          phase.description ? phaseTranslationKeys.push(phase.description.key) : null;

          if (phase.sub_phase_instances?.length) {
            phase.sub_phase_instances.forEach((subPhase: PhaseInstance) => {
              subPhase.description ? phaseTranslationKeys.push(subPhase.description.key) : null;
            });
          }
        });

        if(!phaseTranslationKeys?.length) {
          observer.next({ phases, appointments });
          observer.complete();
          return;
        }

        this.getPhaseContent(phases[0].description, phaseTranslationKeys).subscribe(translations => {
          if (translations) {
            phases.forEach(phase => {
              phase.fillFromCms(translations.find(translation => translation.uid === phase.description?.key));

              if (phase.sub_phase_instances?.length) {
                phase.sub_phase_instances.forEach((subPhase: PhaseInstance) => {
                  if (subPhase.description?.key) {
                    subPhase.fillFromCms(translations.find(translation => translation.uid === subPhase.description?.key));
                  }
                });
              }
            });
          }

          observer.next({ phases, appointments });
          observer.complete();
        }, err => {
          observer.next({ phases, appointments });
          observer.complete();
        });;
      }, () => {
        observer.error();
        observer.complete();
      });
    });
  }


  getTranslatedPhasesAndMaterials(patient_id: string, patient_pathway_id: string): Observable<MaterialPhase[]|null> {
    return new Observable(observer => {
      forkJoin([
        this.getTranslatedPhases(patient_pathway_id),
        this.materialService.getTranslatedMaterials(patient_id, patient_pathway_id)
      ]).subscribe(([phaseData, materialPhases]) => {
        let phases: Array<PhaseInstance> = phaseData.phases;

        let processMaterialPhase = (_materialPhases: MaterialPhase[], _phases: PhaseInstance[]) => {
          _materialPhases.forEach(_materialPhase => {
            const _matchingPhase: PhaseInstance|null = findPhase(_phases, _materialPhase.phase_id);

            if(_matchingPhase) {
              _materialPhase.description = _matchingPhase.description;
              _materialPhase.translated_description = _matchingPhase.translated_description;
              _materialPhase.type = _matchingPhase.type;
            }

            if(_materialPhase.sub_phases?.length) {
              processMaterialPhase(_materialPhase.sub_phases, phases);
            }
          });
        };

        let findPhase = (_phases: PhaseInstance[], phaseID: string) => {
          var foundPhase: PhaseInstance|null;

          for (let i = 0; i < _phases.length; i++) {
            const phase: PhaseInstance = _phases[i];

            if(phase.id == phaseID) {
              foundPhase = phase;
              return foundPhase;
            }

            if(phase.sub_phase_instances?.length) {
              var foundSubPhase: PhaseInstance|null = findPhase(phase.sub_phase_instances, phaseID);
              if(foundSubPhase) {
                return foundSubPhase;
              }
            }
          }

          return null;
        };

        processMaterialPhase(materialPhases, phases);

        observer.next(materialPhases);
        observer.complete();
      });
    });
  }


  getPathwayMessages(patient_pathway_id: string, read_status: string = 'ALL'): Observable<{
    messages: Array<PathwayMessage>,
    pagination: any
  }> {
    const patient_uid = this.getCurrentStoredPatientUid();

    const paramBits = [
      `page=0`,
      `size=999`,
      `read_status=${read_status}`
    ];

    const paramsString = paramBits.join('&');
    const url = `${this.platformUrl}/patients/${patient_uid}/patient-pathways/${patient_pathway_id}/messages?${paramsString}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const messages = result?.items.map((item: any) => {
          return new PathwayMessage(item);
        });

        observer.next({ 'messages': messages, 'pagination': result['pagination'] });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPathwayMessage(patient_pathway_id: string, pathway_message_id: string): Observable<PathwayMessage> {
    const patient_uid = this.getCurrentStoredPatientUid();

    const url = `${this.platformUrl}/patients/${patient_uid}/patient-pathways/${patient_pathway_id}/messages/${pathway_message_id}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const pMessage: PathwayMessage = new PathwayMessage(result);

        this.getPathwayMessageContent(pMessage).subscribe(result => {
          observer.next(pMessage);
          observer.complete();
        });
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPathwayMessageContent(pMessage: PathwayMessage): Observable<any> {
    return new Observable(observer => {
      const locale = this.languageService.getCurrentLanguage()?.locale;
      this.cmsRegionGetDetail(pMessage?.message, locale).subscribe(result => {
        if (result?.paragraphs?.items) {
          const texts: Array<string> = result?.paragraphs?.items.map(item => {
            return item?.text?.text || '';
          });

          pMessage.addMessageContent(texts);
        }

        observer.next(pMessage);
        observer.complete();
      }, err => {
        observer.error(err);
        observer.complete();
      });
    });
  }

  readPathwayMessage(patient_pathway_id: string, pathwayMessage: PathwayMessage, readStatus: boolean = true): Observable<any> {
    const patient_uid = this.getCurrentStoredPatientUid();

    const url = `${this.platformUrl}/patients/${patient_uid}/patient-pathways/${patient_pathway_id}/messages/${pathwayMessage.id}`;

    return new Observable(observer => {
      if (pathwayMessage.read_at && readStatus) {
        observer.next();
        observer.complete();
        return;
      } else if (!pathwayMessage.read_at && !readStatus) {
        observer.next();
        observer.complete();
        return;
      }

      this.authenticatedPatch(url, {}).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      })
    });
  }

  mapPathway(data) {
    const pathway: Pathway = new Pathway(data);

    if (pathway.translationKey) {
      pathway.label = this.translateService.instant(pathway.translationKey);
    }

    return pathway;
  }

  //
  // Important for scope service - Start
  //

  // // This will load a patient by uid and store in storage as current patient
  public setCurrentPatient(uid: string): Observable<Patient> {
    return new Observable((observer) => {
      this.get(uid).subscribe(patient => {
        this.storeCurrentPatientByModel(patient);
        observer.next(patient);
        observer.complete();
      });
    });
  }

  // // Will store a patient in storage as current patient
  public storeCurrentPatientByModel(patient: Patient) {
    localStorage.setItem(PatientService.StorageKeyCurrentPatient, JSON.stringify(patient));
  }

  // // Will retrieve the current patient model from storage
  public getCurrentStoredPatient(): Patient {
    const storedPatient = localStorage.getItem(PatientService.StorageKeyCurrentPatient);

    if (storedPatient) {
      const patient: Patient = new Patient();
      patient.fromStorage(JSON.parse(storedPatient));
      return patient;
    } else {
      return null;
    }
  }

  // // Will retrieve the current patient uid from storage
  public getCurrentStoredPatientUid(): string {
    const patient: Patient = this.getCurrentStoredPatient();

    return patient?.uid;
  }

  // // Will retrieve the current patient hospital uid from storage
  public getCurrentStoredHospitalUid(): string {
    const patient: Patient = this.getCurrentStoredPatient();

    return patient?.hospital_uid;
  }

  public storeCurrentCareModules(modules: CareModule[]) {
    localStorage.setItem(PatientService.StorageKeyCurrentCareModules, JSON.stringify(modules));
  }

  public getCurrentStoredCareModules(): CareModule[] {
    const careModule = localStorage.getItem(PatientService.StorageKeyCurrentCareModules);
    return JSON.parse(careModule);
  }

  //
  // Important for scope service - End
  //
}
