import { PhaseType } from '../enums/phase-type';
import { TranslationInterface } from '../interfaces/translation.interface';
import { Appointment } from './appointment';
import { Material } from './material';

export enum PhaseStatus {
  FINISHED = 'FINISHED',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE'
}

export class PhaseInstance {
  id: string;
  name: TranslationInterface;
  order: number;
  status: PhaseStatus;
  started_at: string;
  finished_at: string;
  appointment_uid: string;
  appointments: Appointment[];
  description: TranslationInterface;
  sub_phase_instances: PhaseInstance[];
  phase_visible: boolean;

  // CMS
  translated_description: string;
  picture: string;
  videos: string[];
  media_selector: string;

  // For Timeline
  type: PhaseType;
  learning_materials: Material[];

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id;
    this.name = item.name;
    this.order = item.order;
    this.status = item.status;
    this.started_at = item.started_at;
    this.finished_at = item.finished_at;
    this.description = item.description;
    this.phase_visible = item.phase_visible;
    this.appointment_uid = item.appointment_uid;
    this.appointments = [];

    if (item.sub_phase_instances && item.sub_phase_instances.length) {
      this.sub_phase_instances = [];
      item.sub_phase_instances.forEach(_ispi => {
        this.sub_phase_instances.push(new PhaseInstance(_ispi));
      });
    }
  }

  fillFromCms(entry) {
    this.translated_description = entry.phase_description;
    this.picture = entry.picture?.url;
    this.media_selector = entry?.media_selector;

    if (entry?.video){
      if (entry?.video?.items?.length) {
        this.videos = entry.video.items;
      } else {
        this.videos = [entry.video];
      }
    }
  }

  public get inTheFuture(): boolean {
    return this.status === PhaseStatus.FUTURE;
  }

  public get isCurrent(): boolean {
    return this.status === PhaseStatus.CURRENT;
  }

  public get isSubPhase(): boolean {
    return this.type === PhaseType.SUBPHASE;
  }

  public get isDummy(): boolean {
    return this.id === "dummy";
  }

  get translationKey(): string {
    return `shared.${this.name?.region}.${this.name?.key}`;
  }
}
