import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTabItem } from '../../../models/page-tab-item';
import { DataService } from '../../../services/data.service';
import { GeneralService } from '../../../services/general.service';
import { MaterialPhase } from '../../../models/material-phase';
import { MaterialService } from '../../../services/material.service';
import { PatientService } from '../../../services/patient.service';
import { Pathway } from 'src/app/models/pathway';
import { Material } from 'src/app/models/material';

@Component({
  selector: 'app-patient-materials',
  templateUrl: './patient-materials.component.html',
  styleUrls: ['./patient-materials.component.scss']
})
export class PatientMaterialsComponent implements OnInit {
  public patientUid: string;
  public patientPathwayUid: string;

  public pathway: Pathway;
  public materialPhasesFlatList: Array<MaterialPhase>;
  public activePhaseID: string;

  public learningMaterials: any[];
  public isLoading: boolean;

  public pageTabItems: PageTabItem[] = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public materialService: MaterialService,
    public patientService: PatientService,
    public generalService: GeneralService
  ) { }

  ngOnInit() {
    this.pageTabItems = [];

    this.route.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;

      this.loadContents();
    });

  }

  loadContents() {
    this.isLoading = true;
    this.patientService.getTranslatedPhasesAndMaterials(this.patientUid,this.patientPathwayUid).subscribe(materialPhases => {
      this.createFlatList(materialPhases);
      this.isLoading = false;

      setTimeout(() => {
        this.updateActiveNav();
      }, 33);
    }, () => {
      this.isLoading = false;
    });
  }

  createFlatList(materialPhases: MaterialPhase[]) {
    this.materialPhasesFlatList = [];

    materialPhases.forEach(materialPhase => {
      this.materialPhasesFlatList.push(materialPhase);

      if(materialPhase?.sub_phases?.length) {
        materialPhase.sub_phases.forEach(materialSubPhase => {
          this.materialPhasesFlatList.push(materialSubPhase);
        });
      }
    });
  }

  @HostListener('window:scroll')
  onScroll() {
    this.updateActiveNav();
  }

  updateActiveNav() {
    if(!this.materialPhasesFlatList?.length) {
      return;
    }

    const bodyContent:any = document.getElementsByClassName('body-content')[0];
    const scroll = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
    const scrollEnd = document.body.offsetHeight - window.innerHeight;
    const margin: number = 150;
    let mtPhase;

    this.materialPhasesFlatList.forEach((item, index) => {
      const htmlID: string = `phase_group_${item.phase_id}`;
      const zone:any = document.getElementById(htmlID);

      if(zone && bodyContent) {
        if (zone.offsetTop - scroll < bodyContent.offsetTop + margin) {
          mtPhase = item;
        }
      }
    });

    if(scroll > 0 && scroll === scrollEnd) {
      mtPhase = this.materialPhasesFlatList[this.materialPhasesFlatList.length-1];
    }

    if(mtPhase) {
      this.setActiveNav(mtPhase.phase_id);
    } else {
      this.setActiveNav(this.materialPhasesFlatList[0].phase_id);
    }
  }

  setActiveNav(phaseID: string) {
    if(phaseID !== this.activePhaseID) {
      this.activePhaseID = phaseID;
    }
  }

  navClick(event, phase_id: string) {
    if (event) {
        event.preventDefault();
    }

    const bodyContent: HTMLElement | null = document.getElementsByClassName('body-content')[0] as HTMLElement;
    const bodyContentStyle = getComputedStyle(bodyContent);
    const margin = parseInt(bodyContentStyle.paddingTop) || 40;

    const htmlID: string = `phase_group_${phase_id}`;

    const targetElement = document.getElementById(htmlID);
    if (targetElement) {
        // Get the top position of the target element relative to the document
        const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;

        // Calculate the final scroll position, adjusting for any offsets
        const scrollPosition = targetPosition - (bodyContent.offsetTop + margin);

        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
      });
    }
  }

  openMaterial(event, materialPhase: MaterialPhase, material: Material) {
    event.preventDefault();

    this.dataService.set(DataService.KeyLearningPhaseSelected, materialPhase);
    this.router.navigateByUrl(`/patient/${this.patientUid}/${this.patientPathwayUid}/learning-materials/article/${material.uid}`);

  }
}
