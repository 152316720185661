import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppointmentSelectionEvent } from '../../../events/appointment-selection-event';
import { AppointmentModalComponent } from '../../../modals/appointment-modal/appointment-modal.component';
import { Appointment } from '../../../models/appointment';
import { DateFormat } from '../../../models/date-format';
import { PageTabItem } from '../../../models/page-tab-item';
import { PhaseInstance, PhaseStatus } from '../../../models/phase-instance';
import { AppointmentService } from '../../../services/appointment.service';
import { DataService } from '../../../services/data.service';
import { GeneralService } from '../../../services/general.service';
import { LocaleService } from '../../../services/locale.service';
import { PatientService } from '../../../services/patient.service';
import { ScopeService } from '../../../services/scope.service';
import { MaterialService } from '../../../services/material.service';
import { PhaseType } from '../../../enums/phase-type';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  public patientUid: string;
  public patientPathwayUid: string;

  public isLoading;
  public pageTabItems: PageTabItem[] = [];
  public phases: PhaseInstance[];
  public futureAppointments: Appointment[] = [];

  public dateFormat: DateFormat;
  public today = new Date();
  public timeZone: string;

  constructor(
    protected dataService: DataService,
    public localeService: LocaleService,
    protected patientService: PatientService,
    protected generalService: GeneralService,
    protected translateService: TranslateService,
    public modalService: BsModalService,
    public appointmentService: AppointmentService,
    public route: ActivatedRoute,
    public scopeService: ScopeService,
    public materialService: MaterialService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.timeZone = this.localeService.getLocalePreferences().locale?.time_zone;
    this.today = new Date();

    this.route.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;

      this.loadPhases();
    });
  }

  loadPhases() {
    this.isLoading = true;

    this.patientService.getTranslatedPhases(this.patientPathwayUid).subscribe(
      (pathway: { phases: PhaseInstance[], appointments: Appointment[] }) => this.phaseHandler(pathway)
      , () => {
        this.isLoading = false;
      });
  }

  phaseHandler(
    pathway: {
      phases: PhaseInstance[],
      appointments: Appointment[]
    }) {
    this.phases = [];

    let currentPhasePageTabItem: PageTabItem;
    const pathwayPhases = pathway.phases;

    pathwayPhases.forEach(phase => {
      const pageTabItem = new PageTabItem(phase?.translationKey, `phase-${phase.id}`);
      this.pageTabItems.push(pageTabItem);
      this.phases.push(phase);

      if (phase.isCurrent) {
        currentPhasePageTabItem = pageTabItem;
      }

      if (phase.sub_phase_instances) {
        phase.sub_phase_instances.forEach(subphase => {
          if (!subphase.inTheFuture) {
            this.phases.push(subphase);
          }
        });
      }
    });

    /*
    ** The use of the dummy - explained:
    ** - - - - - - - - - - - - - - - - -
    **
    ** In the loop of phases we render a block for the phase or subphase title.
    ** and before that, if truethfull to the conditionals, we render the upcoming appointments and next steps titles.
    ** but in case we only have 1 (main) phase or our current phase is also the last phase, we encouter a problem:
    ** The upcoming and next steps titles will never be able to render as last, because in the loop it is placed before
    ** the rendering of the block for a phase or subphase.
    ** Therefor we need to use a dummy, which always comes last, but is never rendered.
    **
    */
    this.phases.push(new PhaseInstance({ id: 'dummy', order: 999999, type: PhaseType.PHASE, status: PhaseStatus.FUTURE }));
    /*
    ** End - of dummy info
    */

    this.phases = this.mapAppointmentsToPhases(pathway.appointments, this.phases);
    this.isLoading = false;

    if (currentPhasePageTabItem) {
      setTimeout(() => {
        this.generalService.scrollToPageTabItem(currentPhasePageTabItem);
      }, 250);
    }
  }

  mapAppointmentsToPhases(appointments: Appointment[], phases: PhaseInstance[]): PhaseInstance[] {

    appointments.forEach((appointment: Appointment) => {
      const appointmentStartDate = moment(new Date(appointment.start_date)).tz(this.timeZone);

      if (appointmentStartDate.isSameOrAfter(this.today, 'day')) {
        this.futureAppointments.push(appointment);
      } else {

        if (appointment.created_for_phase_id) {
          const phase = phases.find((ph: PhaseInstance) => ph.id === appointment.created_for_phase_id);

          if (phase) {
            phase.appointments.push(appointment);
          }

        } else {
          const phase = phases.find((ph: PhaseInstance) => ph.appointment_uid === appointment.uid);

          if (phase) {
            phase.appointments.push(appointment);

          } else {
            const phase = phases.find((ph: PhaseInstance, index) => {
              const phaseStartDate = moment(new Date(ph.started_at)).tz(this.timeZone);

              if (!phases[index + 1]) {
                return appointmentStartDate.isSameOrAfter(phaseStartDate);
              }

              const nextPhaseStartDate = moment(new Date(phases[index + 1].started_at)).tz(this.timeZone);
              const isAfterStartAndBeforeNext = appointmentStartDate.isSameOrAfter(phaseStartDate) && appointmentStartDate.isBefore(nextPhaseStartDate);
              const isAfterStartAndNextDateInvalid = appointmentStartDate.isSameOrAfter(phaseStartDate) && !nextPhaseStartDate.isValid();

              return isAfterStartAndBeforeNext || isAfterStartAndNextDateInvalid;
            });

            if (phase) {
              phase.appointments.push(appointment);
            }
          }
        }
      }
    });

    phases.forEach((phase: PhaseInstance) => {
      if (phase.appointments.length > 1) {
        this.generalService.sortByKey(phase.appointments, 'start_date');
      }
    });
    this.generalService.sortByKey(this.futureAppointments, 'start_date');

    return phases;
  }

  printToday(phaseIndex: number): boolean {
    const phase: PhaseInstance = this.phases[phaseIndex];
    const phaseStartDate = moment(new Date(phase.started_at)).tz(this.timeZone);
    const prevPhase: PhaseInstance = this.phases[phaseIndex - 1];

    if (!prevPhase) {
      return phaseStartDate.isSameOrAfter(this.today, 'day');
    }

    const prevPhaseStartDate = moment(new Date(prevPhase.started_at)).tz(this.timeZone);
    const todayisBetweenPrevAndCurrent = phaseStartDate.isSameOrAfter(this.today, 'day') && prevPhaseStartDate.isBefore(this.today, 'day');
    const todayIsPastPrevAndCurrentDateInvalid = !phaseStartDate.isValid() && prevPhaseStartDate.isBefore(this.today, 'day');

    return todayisBetweenPrevAndCurrent || todayIsPastPrevAndCurrentDateInvalid;
  }

  printUpcoming(phaseIndex: number): boolean {
    const phase = this.phases[phaseIndex];

    if (!phaseIndex) {
      return (phase.inTheFuture);
    }

    const prevPhase = this.phases[phaseIndex - 1];

    return (!prevPhase.inTheFuture && phase.inTheFuture);
  }

  selectAppointment(event, appointment) {
    this.appointmentService.getAppointment(appointment.uid).subscribe((app: Appointment) => {
      this.openAppointmentModal(new AppointmentSelectionEvent(event, app));
    });
  }

  openAppointmentModal(appointmentSelectionEvent: AppointmentSelectionEvent) {
    const time_24_hours = this.localeService.getLocalePreferences().locale.time_24_hours;

    const initialState = {
      appointment: appointmentSelectionEvent.context,
      dateFormat: this.dateFormat,
      time_24_hours: time_24_hours
    };

    const modalref = this.modalService.show(AppointmentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  goToMaterialPhase(event, phase: PhaseInstance) {
    event.preventDefault();

    this.router.navigate([
      '/patient',
      this.patientUid,
      this.patientPathwayUid,
      'learning-materials'
    ], { queryParams: { phase: phase.id } });
  }
}
